import { Button, Form, Input, Checkbox, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { authApi } from '../../../api';
import './login.scss';
import patchDynamic from '../../../helper/pathDynamic';

export const Login = () => {
  const [api, showPopup] = notification.useNotification();
  const alertLoginSuccess = (message: string) => {
    api.success({
      message,
      placement: 'topRight',
      duration: 1,
    });
  };
  const alertLoginFail = (message: string) => {
    api.error({
      message,
      placement: 'topRight',
      duration: 3,
    });
  };

  // Handle Data Login
  const navigate = useNavigate();
  async function handleLogin(dataLogin: any) {
    try {
      const res: any = await authApi.login(dataLogin);
      if (res?.status === 'success') {
        localStorage.setItem('access_token', JSON.stringify(res.data?.access_token));
        localStorage.setItem('expires_at', JSON.stringify(res.data?.expires_at));
        localStorage.setItem('email', JSON.stringify(dataLogin.email));
        localStorage.setItem('role_user', JSON.stringify(res.data?.role));
        alertLoginSuccess('Login Success');
        setTimeout(() => {
          // navigate('/admin');
          navigate(`/admin-${patchDynamic}`);
        }, 1000);
      }
    } catch (err) {
      alertLoginFail('Login Fail');
    }
  }

  // Handle Submit
  const onFinish = (values: any) => {
    handleLogin({
      email: values.email,
      password: values.password,
    });
  };

  return (
    <div className="wrapper-login">
      <div className="form-login">
        {showPopup}
        <h2>Color Simulation Login</h2>
        <Form
          name="wrap"
          labelCol={{ flex: '160px' }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          className="login-form"
          onFinish={onFinish}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              { required: true, message: 'Please input your email!' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <div className="option">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <a className="login-form-forgot" target="_blank" href="https://hc.fortuna-inc.jp/sec/archives/docs/51">
              Forgot password
            </a>
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
            Or <Link to="/register">register now!</Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
