import { Space, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { removeLocalStorage } from '../../utils/localStorage';
import patchDynamic from '../../helper/pathDynamic';

const Header = () => {
  const handleLogout = () => {
    removeLocalStorage('access_token');
    removeLocalStorage('role_user');
    localStorage.removeItem('idTagActive');
    localStorage.removeItem('idTabOpen');
    return (window.location.href = `/login-${patchDynamic}`);
  };

  return (
    <div className="header">
      <div className="logo">
        <a href={`${window.location.protocol}//${window.location.host}/admin-${patchDynamic}`}>
          Color Simulation Admin
        </a>
      </div>
      <div className="header-right">
        <Space>
          <a href="https://hc.fortuna-inc.jp/sec/" target="noopener" className="helper" title="操作ヘルプ">
            操作ヘルプ
          </a>
          <div className="logout" onClick={() => handleLogout()}>
            <Button>
              <LogoutOutlined />
              ログアウト
            </Button>
          </div>
        </Space>
      </div>
    </div>
  );
};

export default Header;
