import moment from 'moment';
import { useEffect } from 'react';
import { Button, Layout, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getInformation } from '../../../redux/services/userSlice';
import { getOrders } from '../../../redux/services/orderSlice';
import { DataTable } from '../../../types';
import { textData } from '../../../utils/dashboardData';
import './Dashboard.scss';
import { getLocalStorage } from '../../../utils/localStorage';
import patchDynamic from '../../../helper/pathDynamic';

const { Content } = Layout;

export const Dashboard = () => {
  const role_user: any = getLocalStorage('role_user');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfor } = useSelector((state: any) => state.userReducer);
  const { orders, loadingOrder } = useSelector((state: any) => state.orderReducer);
  const linkOrder = window.location.host;

  useEffect(() => {
    dispatch(getInformation());
    dispatch(getOrders());
    // eslint-disable-next-line
  }, []);

  const loginEmail =
    localStorage.getItem('email') === null ? 'admin@gmail.com' : JSON.parse(localStorage.getItem('email')!);

  const getColumnSearchProps = (dataIndex: string) => ({
    render: (text: any, record: any, index: any) => {
      if (dataIndex === 'name') {
        return (
          <a href={record.url} target="_blank">
            {record.url}
          </a>
        );
      }
      if (dataIndex === 'datetime') {
        return <span>{moment(record.created_at).format('YYYY.MM/DD HH:mm')}</span>;
      }
    },
  });

  const columns: ColumnsType<DataTable> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '10%',
      align: 'center',
    },
    {
      title: '日時',
      dataIndex: 'created_at',
      width: '25%',
      align: 'center',
      ...getColumnSearchProps('datetime'),
    },
    {
      title: '公開URL',
      dataIndex: 'URL',
      render: (text, record, index) => (
        <Link onClick={() => handleSelectedProduct(record)} to={`/overview/${record.id}`} target="_blank">
          <span style={{ textDecoration: 'underline' }}>{`${linkOrder}/overview/${record.id}`}</span>
        </Link>
      ),
    },
  ];
  const handleSelectedProduct = (id: any) => {
    localStorage.setItem('product_id', id);
  };

  return (
    <Layout className="style-color" style={{ padding: '24px' }}>
      <Content className="site-layout-background content-wrap">
        <div style={{ width: '100%' }}>
          <div className="tableInfor">
            <div className="tableInfor-wrap">
              <div className="tableInfor-group">
                <h2>ダッシュボード</h2>
                <div className="personalGroup">
                  <div className="row-group">
                    <label>公開URL</label>
                    <span>
                      <Link className="home_url" target={'_blank'} to="/">
                        {window.location.host}
                      </Link>
                    </span>
                  </div>
                  <div className="row-group role-account">
                    <label>管理者アカウント</label>
                    <p>
                      {loginEmail}
                      <span className={`role ${role_user === 0 ? 'free' : 'business'}`}>
                        {role_user === 0 ? 'Free' : 'Business'}
                      </span>
                    </p>
                  </div>
                  <div className="row-group">
                    <label>最終更新日時</label>
                    <span>{moment(userInfor?.last_update).format('YYYY.MM/DD HH:mm')}</span>
                  </div>
                </div>
              </div>
              <div className="tableInfor-group">
                <h2>操作ガイド</h2>
                <div className="personalGroup">
                  {textData?.map((textDataItem) => {
                    return (
                      <div key={textDataItem.key}>
                        <a href={textDataItem.url} target="_blank">
                          {textDataItem.status}
                        </a>
                      </div>
                    );
                  })}
                  <div className="row-group"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tableSaveProduct" style={{}}>
          <div style={{ padding: '13px', fontWeight: '600', fontSize: '24px' }}>保存された商品パターン</div>
          <Table rowKey="key" loading={loadingOrder} columns={columns} dataSource={orders?.data} pagination={false} />
          <div style={{ padding: '20px', textAlign: 'center' }}>
            <Button
              type="primary"
              onClick={() => {
                localStorage.setItem('idTagActive', JSON.stringify('parent-item-2'));
                navigate(`/admin-${patchDynamic}/account/selected-products`);
              }}
            >
              すべてを見る
            </Button>
          </div>
        </div>
      </Content>
    </Layout>
  );
};
