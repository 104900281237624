const path_URL: string = window.location.hostname;

const checkPath = (path_URL: string) => {
  switch (path_URL) {
    // FORTUNA
    case 'colorsimulation.com':
      return 'https://api.colorsimulation.com/api';

    // STG FORTUNA
    case 'stg.colorsimulation.com':
      return 'https://stg.api.colorsimulation.com/api';

    // RAWLINGS
    case 'rawlings.colorsimulation.com':
      return 'https://api.rawlings.colorsimulation.com/api';

    // STG RAWLINGS
    case 'stg-rawlings.colorsimulation.com':
      return 'https://api.stg-rawlings.colorsimulation.com/api';

    // DEUX
    case 'deuxfoyer.colorsimulation.com':
      return 'https://api.deux.colorsimulation.com/api';

    // STG DEUX
    case 'stg-deuxfoyer.colorsimulation.com':
      return 'https://api.stg-deux.colorsimulation.com/api';

    // ISOL
    case 'isol.colorsimulation.com':
      return 'https://api.isol.colorsimulation.com/api';

    // STG ISOL
    case 'stg-isol.colorsimulation.com':
      return 'https://api.stg-isol.colorsimulation.com/api';

    // LOCAL
    case 'localhost':
      return 'http://127.0.0.1:8000/api';
  }
};

const urlApi = checkPath(path_URL);

export default urlApi;
