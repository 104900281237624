import logo from '../../assets/images/logo_header.svg';

export default function HeaderCustomer() {
  return (
    <header className="header_client">
      <a href="/">
        <img src={logo} alt="" />
        <h2>
          OFFICIAL GLOVE, BALL & HELMET OF MAJOR LEAGUE BASEBALL<sup>®</sup>
        </h2>
      </a>
    </header>
  );
}
