import { configureStore } from '@reduxjs/toolkit';
import orderReducer from '../services/orderSlice';
import productReducer from '../services/productSlice';
import styleColorReducer from '../services/styleColorSlice';
import tagProductReducer from '../services/tagSlice';
import userReducer from '../services/userSlice';
import textStyleReducer from '../services/textStyleSlice';

const store = configureStore({
  reducer: {
    productReducer: productReducer,
    userReducer: userReducer,
    orderReducer: orderReducer,
    styleColorReducer: styleColorReducer,
    textStyleReducer: textStyleReducer,
    tagProductReducer,
  },
});

export default store;
