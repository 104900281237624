import { Button, Form, Input, notification } from 'antd';
import { Link } from 'react-router-dom';
import { authApi } from '../../../api';
import './register.scss';

export const Register = () => {
  const [api, showPopup] = notification.useNotification();
  const alertRegisterSuccess = (message: string) => {
    api.success({
      message,
      placement: 'topRight',
      duration: 1,
    });
  };
  const alertRegisterFail = (message: string) => {
    api.error({
      message,
      placement: 'topRight',
      duration: 3,
    });
  };

  // Handle Data Register
  const [form] = Form.useForm();
  async function handleRegister(dataRegister: any) {
    try {
      const res: any = await authApi.register(dataRegister);
      if (res.status === 'success') {
        form.resetFields();
        alertRegisterSuccess('Register Success');
      }
    } catch (err: any) {
      if (err.response.data.data.errors?.email) {
        alertRegisterFail('The email has already been taken');
      } else if (err.response.data.data.errors?.password) {
        alertRegisterFail('The password confirmation does not match');
      } else {
        alertRegisterFail('Register Fail');
      }
    }
  }

  // Handle Submit
  const onFinish = (values: any) => {
    handleRegister({
      email: values.email,
      password: values.password,
      password_confirmation: values.password_confirmation,
    });
  };

  return (
    <div className="wrapper-register">
      <div className="form-register">
        {showPopup}
        <h2>Color Simulation Register</h2>
        <Form
          name="wrap"
          labelCol={{ flex: '160px' }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              { required: true, message: 'Please input your email!' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Repeat Password"
            name="password_confirmation"
            rules={[{ required: true, message: 'Please input your repeat password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="btn-register">
              Register
            </Button>
            Or <Link to="/login">login now!</Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
