import { Layout } from 'antd';
import '../assets/styles/Admin/style.scss';
import Header from '../components/header';
import Sidebar from '../components/sidebar';

const AdminLayout = ({ children }: any) => {
  return (
    <Layout>
      <Header />
      <Layout>
        <Sidebar />
        {children}
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
