import { Routes, Route } from 'react-router-dom';
import { adminRoutes, customerRoutes, publicRoutes } from './routes';
import DefaultLayout from './layouts/DefaultLayout';
import { Fragment, Suspense } from 'react';
import ProtectedRoutes from './routes/protectedRoutes';
import Error from './components/Error';
import { Spin } from 'antd';

function App() {
  const publicRoute = publicRoutes.map((route) => {
    const Page = route.component;
    let Layout: any = DefaultLayout;

    if (route.layout) {
      Layout = route.layout;
    } else if (route.layout === null) {
      Layout = Fragment;
    }

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <Layout>
            <Page />
          </Layout>
        }
      />
    );
  });

  const adminRoute = adminRoutes.map((route: any) => {
    const Page = route.component;
    let Layout: any = DefaultLayout;

    if (route.layout) {
      Layout = route.layout;
    } else if (route.layout === null) {
      Layout = Fragment;
    }

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <Layout>
            <Page />
          </Layout>
        }
      />
    );
  });

  const customerRoute = customerRoutes.map((route: any) => {
    const Page = route.component;
    let Layout: any = Fragment;
    if (route.layout) {
      Layout = route.layout;
    } else {
      Layout = Fragment;
    }
    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <Layout>
            <Page />
          </Layout>
        }
      />
    );
  });

  return (
    <div className="App">
      <Suspense fallback={<Spin />}>
        <Routes>
          {/* {Login and Register} */}
          {publicRoute}

          {/* Page Admin */}
          <Route element={<ProtectedRoutes />}>{adminRoute}</Route>

          {/* page Customer */}
          {customerRoute}

          {/* Page Not Found */}
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
