import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { productApi } from '../../../api';
import { removeLocalStorage, setLocalStorage } from '../../../utils/localStorage';
import routes from '../../../config/routes';
import FooterCustomer from '../../../components/FooterCustomer';

export const ChooseProduct = () => {
  const navigate = useNavigate();
  const [listProduct, setListProduct] = useState<any[]>([]);

  const listKeyRemove = [
    'activeTag',
    'productTag',
    'arrayActive',
    'data_orders',
    // 'productDetail',
    'styleColorTextActive',
    'listDataText',
    'fontIdActive',
  ];

  const handleRemoveListKey = () => {
    listKeyRemove.forEach((key: string) => {
      removeLocalStorage(key);
    });
  };

  const handleClearDataOrder = () => {
    handleRemoveListKey();
    // setLocalStorage('data_orders', { styleActive: {} });
  };

  const clickHandlerStyle = async (id: any) => {
    handleClearDataOrder();
    try {
      const resData: any = await productApi.getCustomerProductsById(id);
      if (resData.status === 'success') {
        setLocalStorage('productDetail', resData.data);
        navigate(routes.chooseColors);
      }
    } catch (err) {
      console.log('削除に失敗しました。');
    }
  };

  const getAllProduct = async () => {
    try {
      const resData: any = await productApi.getCustomerAllProduct();
      if (resData.status === 'success') {
        setListProduct(resData.data);
      }
    } catch (err) {
      console.log('Get All Product Fail.');
    }
  };

  useEffect(() => {
    getAllProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   // Check Data Tag Local
  //   const activeTagLocal = localStorage.getItem('activeTag');
  //   const handleActiveTag: any = activeTagLocal ? JSON.parse(activeTagLocal || '') : '';
  //   if (!handleActiveTag) {
  //     navigate(routes.home);
  //   }

  //   const getDataStorage = JSON.parse(localStorage.getItem('productTag') || '[]');
  //   setListProduct(getDataStorage);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <section className="main backgroundpc1-4">
      <div className="container wrapper-page">
        <div className="title-pc1-4">
          Which <span className="pattern">pattern</span> would you like?
        </div>
        <div className="title-small">web options available after choosing a glove </div>
        <div className="list-product-by-tag">
          {listProduct.map((product) => (
            <div
              key={product.id}
              className="product-item-tag"
              onClick={() => {
                clickHandlerStyle(product.id);
              }}
            >
              <div className="image-product">
                <img src={product.image} alt="" />
              </div>
              <h4 className="name-product">{product.name}</h4>
            </div>
          ))}
        </div>
      </div>

      {FooterCustomer()}
    </section>
  );
};
